export default () => {
	const closeBtn = document.querySelectorAll('#parts-descriptions .close-btn')
	closeBtn.forEach((btn) => {
		btn.addEventListener('click', () => {
			document.querySelector('#parts-descriptions .part-description.active').classList.remove('active')
			document.querySelector('#car-container img.active').classList.remove('active')
			document.querySelector('#car-container .rb-btn.active').classList.remove('active')
		})
	})
	const buttons = document.querySelectorAll('#car-container .rb-btn')
	buttons.forEach((btn) => {
		btn.addEventListener('click', () => {
			if (btn.classList.contains('active')) {
				btn.classList.remove('active')
				document.querySelector('#parts-descriptions .part-description.active').classList.remove('active')
				document.querySelector('#car-container img.active').classList.remove('active')
			} else {
				buttons.forEach((button) => {
					button.classList.remove('active')
				})
				if (document.querySelector('#parts-descriptions .part-description.active')) {
					document.querySelector('#parts-descriptions .part-description.active').classList.remove('active')
					document.querySelector('#car-container img.active').classList.remove('active')
				}
				btn.classList.add('active')
				switch (btn.dataset.part) {
					case 'rb-airbag':
						document.querySelector('#parts-descriptions .description-airbag').classList.add('active')
						document.querySelector('#car-container img[alt=airbag]').classList.add('active')
						break
					case 'rb-body':
						document.querySelector('#parts-descriptions .description-body').classList.add('active')
						document.querySelector('#car-container img[alt=body]').classList.add('active')
						break
					case 'rb-dashboard':
						document.querySelector('#parts-descriptions .description-dashboard').classList.add('active')
						document.querySelector('#car-container img[alt=dashboard]').classList.add('active')
						break
					case 'rb-ecu':
						document.querySelector('#parts-descriptions .description-ecu').classList.add('active')
						document.querySelector('#car-container img[alt=ecu]').classList.add('active')
						break
					case 'rb-seats':
						document.querySelector('#parts-descriptions .description-seats').classList.add('active')
						document.querySelector('#car-container img[alt=seats]').classList.add('active')
						break
					case 'rb-stearing':
						document.querySelector('#parts-descriptions .description-stearing').classList.add('active')
						document.querySelector('#car-container img[alt=stearing]').classList.add('active')
						break
					default:
						document.querySelector('#parts-descriptions .description-suspension').classList.add('active')
						document.querySelector('#car-container img[alt=suspension]').classList.add('active')
						break
				}
			}
		})
	})
}