import Swiper from 'swiper'

export default () => {
	document
		.querySelectorAll('#parts-descriptions .swiper-container')
		.forEach((element) => {
			// Slider
			let swiper = new Swiper(element, {
				slidesPerView: 1,
				spaceBetween: 100,
				loop: true,
				autoHeight: true,
				breakpoints: {
					1200: {
						autoHeight: false,
					}
				}
			})
			// Navigation
			element.querySelectorAll('.btn-prev').forEach((btn) => {
				btn.addEventListener('click', () => swiper.slidePrev(500))
			})
			element.querySelectorAll('.btn-next').forEach((btn) => {
				btn.addEventListener('click', () => swiper.slideNext(500))
			})
		})
}